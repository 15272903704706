import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ItemDetail from './ItemDetail/ItemDetail'
import { ItemProvider, ItemConsumer } from '../../../context/ItemContext'
import Aux from '../../../hoc/Aux'

class ItemScreen extends Component {

    async componentDidMount() {

    }

    render() {
        console.log('this.props.match.path in items')
        console.log(this.props.match.path)
        const parentPath = this.props.match.path.replace('/items', '') // temp

        return (
            <ItemProvider>
                <div>
                    <h1>Items</h1>
                    <Link to= { parentPath }>Dashboard</Link>
                    <div>Add new item component</div>
                    <div>Items</div>
                    <div className="items">
                        <ItemConsumer>
                            { ( { items, saveItem }) =>
                                <Aux>
                                    { items.map( item => <ItemDetail item={item} key={item.itemId} />) }
                                    (ADD NEW ITEM)
                                    <ItemDetail clearOnSave={true}/>
                                </Aux>
                            }
                        </ItemConsumer>
                    </div>
                </div>
            </ItemProvider>
        )
    }
}

export default ItemScreen