import React, { createContext } from 'react'
import { rest } from '../tools.js'
import axios from 'axios'

const UserContext = createContext({
    isVerified: false,
    isUserLoaded: false,
    user: {},
    updateName: () => {},
    forgetUser: () => {}
})

export class UserProvider extends React.Component {

    // NOTE state variable is just above render because setting functions doesn't work until its defined

    async componentDidMount() {

        console.log('userprovider cdm')
        let userId = localStorage['userId']

        console.log(userId + ' from localstorage')

        if (userId) {

            let res = await axios.get(rest.getUri('user/' + userId))
            console.log(res)
            const user = res.data

            this.setState({
                isVerified: true,
                isUserLoaded: true,
                user: user
            })
        } else {
            this.setState({ isVerified: true })
        }
    }

    updateName = async ( newName ) => {
        console.log("updateName called: " + newName)
        let user, requestUri = null
        const userId = this.state.user.userId

        if (userId) {
            requestUri = rest.getUri(rest.endpoint.user + userId)
        } else {
            requestUri = rest.getUri(rest.endpoint.user)
        }

        let res = await axios.post(requestUri, { name: newName })
        user = res.data
        console.log(user)
        this.setState({
            isVerified: true,
            isUserLoaded: true,
            user: user
        })
        localStorage['userId'] = user.userId

        console.log('set localstorage userid to ' + user.userId)
        console.log(localStorage)
    }

    forgetUser() {
        // TOOD: if there is an open pending order, cancel it
        delete localStorage['orderId']
        delete localStorage['userId']
    }

    state = {
        isVerified: false,
        user: {},
        updateName: this.updateName,
        forgetUser: this.forgetUser
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export const UserConsumer = UserContext.Consumer