import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Aux from '../../hoc/Aux'
import DashboardHome from './DashboardHome/DashboardHome'
import ItemScreen from './ItemScreen/ItemScreen'
import FileNotFoundScreen from '../FileNotFoundScreen/FileNotFoundScreen'

class DashboardScreen extends Component {

    state = {

    }

    render() {
        const parentPath = this.props.match.path.replace('/*', '')

        return (
            <Aux>
                Dashboard
                <Switch>
                    <Route exact path={ parentPath } component={ DashboardHome } />
                    <Route exact path={ parentPath + "/items" } component={ ItemScreen } />
                    <Route component={ FileNotFoundScreen } />
                </Switch>
            </Aux>
        )
    }
}

export default DashboardScreen