import React from 'react'
import { Route, Switch } from 'react-router-dom'
// import logo from './logo.svg'
import './App.scss'
import WelcomeScreen from './components/WelcomeScreen/WelcomeScreen'
import OrderScreen from './components/OrderScreen/OrderScreen'
import CartScreen from './components/CartScreen/CartScreen'
import KitchenScreen from './components/KitchenScreen/KitchenScreen'
import DashboardScreen from './components/DashboardScreen/DashboardScreen'
import FileNotFoundScreen from './components/FileNotFoundScreen/FileNotFoundScreen'

import { UserProvider, UserConsumer } from './context/UserContext'
import { OrderProvider } from './context/OrderContext';
import {Helmet} from 'react-helmet'
import Pusher from 'pusher-js'

class App extends React.Component {

    title = "Home Café"
    state = {
        showCart: false
    }

    componentDidMount() {
        console.log('componentDidMount')

        if (process.env.NODE_ENV === 'development') {
            Pusher.logToConsole = true;
        }

        console.log('env')
        console.log(process.env)
    }

    // user { user.userId ? 'is set' : 'not set'}
    render() {
        return (
            <OrderProvider>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                </Helmet>
            <UserProvider>
                <UserConsumer>
                    {({ isVerified, user, forgetUser }) => (
                        <div className="app">
                            { !isVerified ? 'loading ... (or nothing)' : null }
                            <div className="content">
                                <Switch>
                                    <Route exact path="/" render={ () => <WelcomeScreen title={this.title} /> } />
                                    <Route exact path="/order" component={ OrderScreen } />
                                    <Route exact path="/cart" component={ CartScreen } />
                                    <Route exact path="/kitchen" component={ KitchenScreen } />
                                    <Route exact path="/dashboard" component={ DashboardScreen } />
                                    <Route exact path="/dashboard/*" component={ DashboardScreen } />
                                    <Route component={ FileNotFoundScreen } />
                                </Switch>
                            </div>
                            <div className="footer">
                                {this.title}<br />
                                { user.name
                                    ? <button className="red forget-me-button" onClick={() => {forgetUser(); window.location.reload(true)}}>Forget Me</button>
                                    : null }
                            </div>
                        </div>
                    )}
                </UserConsumer>
            </UserProvider>
            </OrderProvider>
        )
    }
}

export default App;
