import React from 'react'
import OptionCheckbox from './OptionCheckbox'
import OptionSelect from './OptionSelect'
import './OrderItemOption.scss'

const orderItemOption = (props) => {
    let option = props.option
    console.log('orderItemOption rendered')

    let saveOption = (event) => {

        let name = event.target.name
        let selected = event.target.checked
        console.log("saving item")
        console.log(name)

        switch(option.type) {
            case 'bool':
                if (selected) {
                    props.setOption(option)
                } else {
                    props.removeOption(option)
                }
                break
            case 'select':
                // make sure there are selected options
                // if none, call remove
                // if 2, remove the old and set the new
                if (selected) {

                    let optionListItem = option.optionList.filter( opt => opt.optionListItemId === name)[0]
                    // todo: if user messes with IDs, above might fail or be null
                    option.options = [optionListItem]

                    props.setOption(option)
                } else {
                    props.removeOption(option)
                }

                break
            case 'multi-select':
                if (option.options == null) option.options = []

                if (!selected && option.options.length === 0) {
                    props.removeOption(option)
                } else {
                    if (selected) {
                        option.options.push(name)
                    } else {
                        option.options = option.options.map( opt => opt !== name )
                    }

                    props.setOption(option)
                }
                break
            default:
                console.log("found an unsupported option type: " + option.type)
                break;
        }



    }

    let renderedOption = null

    let selectionDetails = Object.values(props.selectedOptions).filter( opt => opt.optionId === option.optionId)[0]
console.log('this item is selected?')
console.log(selectionDetails)
    switch(option.type) {
        case 'bool':
            renderedOption = (
                <OptionCheckbox option={option} saveOption={saveOption} isSelected={selectionDetails != null} />
            )
            break
        case 'select':
        case 'multi-select':
            let options = option.optionList.map( opt => opt)
            let detail = <OptionSelect multi={option.type === 'multi-select'} parentOption={option} options={options} saveOption={saveOption} selectionDetails={selectionDetails} />

            renderedOption = (
                <div>{ detail }</div>
            )
            break
        default:
            console.log("found an unsupported option type: " + option.type)
            break;
    }
    return (
        <div className="option-group">
            <span className="option-title">{option.name}</span>
            {( option.isRequired ? <span className="required">*</span> : null)}
            { renderedOption }
        </div>
    )
}

export default orderItemOption