import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { OrderConsumer } from '../../context/OrderContext'
import { UserConsumer } from '../../context/UserContext'
import Aux from '../../hoc/Aux'
import OrderItemSummary from './OrderItemSummary/OrderItemSummary'
import './CartScreen.scss'
import classNames from 'classnames'
import ORDER_STATE from '../../enums/OrderState'

class CartScreen extends Component {

    // todo: remove this?
    state = {
        items: []
    }

    isLocalOrder(order) {
        return order.status === ORDER_STATE.LOCAL || order.status == null || order.status === ORDER_STATE.CANCELLED
    }

    render() {
        return (
            <Aux>
                <UserConsumer>
                    {({ isVerified, isUserLoaded }) =>
                        <Aux>
                            { isVerified && !isUserLoaded ? <Redirect to="/"/> : null }
                        </Aux>
                    }
                </UserConsumer>
                <OrderConsumer>
                    { ({isVerified, order, submitOrder, cancelOrder, createNewOrder}) => {
                            return (
                                <div className="cart-screen">
                                    {(isVerified && order.items.length === 0 ? <Redirect to="/order"/> : null)}
                                    {(order.status !== ORDER_STATE.SUBMITTED
                                        && order.status !== ORDER_STATE.IN_PROGRESS
                                        && order.status !== ORDER_STATE.COMPLETED
                                        ? <Link className="back-button" to="/order">&lt; Menu</Link>
                                        : null
                                    )}
                                    <br />
                                    <div>
                                        <div className="order-status">Order Status: <span className={classNames(["order-status-value",order.status])}>{ (order.status ? ORDER_STATE.getFormattedValue(order.status) : 'Not Submitted' ) }</span></div>
                                        {( this.isLocalOrder(order) ? <button className="green" onClick={() => { submitOrder() }}>Submit Order</button> : null)}
                                        {( order.status === ORDER_STATE.SUBMITTED ?
                                                <Aux>
                                                    <div className="order-message">
                                                        <div className="submitted-info">Submitted on <span className="submitted-date">{new Date(order.submitTime).toLocaleDateString()}</span> @ <span className="submitted-time">{new Date(order.submitTime).toLocaleTimeString()}</span></div>
                                                        This screen will refresh when your order is being prepared. Please wait for this order before creating a new one.
                                                    </div>
                                                    <button className="red" onClick={() => { cancelOrder() }}>Cancel Order</button>
                                                </Aux>
                                            : null)}
                                        {( order.status === ORDER_STATE.IN_PROGRESS ? <span>You Cannot Cancel Your Order. Please shout out to your barista for any changes.</span> : null)}
                                        {( order.status === ORDER_STATE.COMPLETED ?
                                                <Aux>
                                                    <div className="order-message">
                                                        <div>Your order is ready and will be out momentarily. Press the button below to create a new order</div>
                                                    </div>
                                                    <button className="green" onClick={() => createNewOrder()}>New Order</button>
                                                </Aux>
                                            : null)}
                                    </div>
                                    <OrderItemSummary order={order} showRemoveButton={this.isLocalOrder(order) }/>
                                </div>
                            )
                        }
                    }
                </OrderConsumer>
            </Aux>
        )
    }
}

export default CartScreen
