const ORDER_STATE = {
    SUBMITTED: 'submitted',
    IN_PROGRESS: 'inprogress',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled',
    LOCAL: 'local',

    getFormattedValue: (value) => {
        switch(value) {
            case ORDER_STATE.SUBMITTED:
                return 'Submitted'
            case ORDER_STATE.IN_PROGRESS:
                return 'In Progress'
            case ORDER_STATE.COMPLETED:
                return 'Completed'
            case ORDER_STATE.CANCELLED:
                return 'Cancelled'
            case ORDER_STATE.LOCAL:
                return 'Local'
            default:
                return ''
        }
    }
}

export default ORDER_STATE