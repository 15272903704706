import React, { Component } from 'react'
import './ItemOption.scss'

class ItemOption extends Component {

    componentDidMount() {
        let option = this.props.option

        if (option.name == null) {
            option.name = ''
        }

        this.setState({
            option: option
        })
    }

    state = {
        option: {
            name: '',
            description: '',
            isRequired: false
        } // TODO: change this to just have the state be the option
    }

    updateOptionType = (event) => {

        let option = this.state.option
        option.type = event.target.value

        if (option.type === 'select' || option.type === 'multi-select') {
            option.optionList = [
                {
                    optionListItemId: (new Date()).getTime(),
                    name: ''
                }
            ]
        } else {
            option.optionList = []
        }

        this.setState({
            option: option
        })
    }

    updateOptionName = (event) => {
        const option = this.state.option

        option.name = event.target.value

        this.setState({
            option: option
        })
    }

    updateOptionRequired = (event) => {
        const option = this.state.option

        option.isRequired = !option.isRequired

        this.setState({
            option: option
        })
    }

    // updateOptionDescription(event) {
    //     const option = this.state.option

    //     option.description = event.target.value

    //     this.setState({
    //         option: option
    //     })
    // }

    addOptionItem = () => {
        const option = this.state.option

        option.optionList.push({
            optionListItemId: (new Date()).getTime(),
            name: ''
        })

        this.setState({
            option: option
        })
    }

    updateOptionItemValue = (optionListItem, value) => {

        const option = this.state.option

        option.optionList.forEach( opt => {
            if (opt.optionListItemId === optionListItem.optionListItemId) {
                opt.name = value
            }
        })

        this.setState({
            option: option
        })
    }

    removeOptionItem = ( optionListItem ) => {
        console.log('removing item ' + optionListItem.optionListItemId)
        const option = this.state.option

        console.log(option)
        option.optionList = option.optionList.filter(function( opt ) {
            return opt.optionListItemId !== optionListItem.optionListItemId;
        })

        console.log(option.optionList)

        this.setState({
            option: option
        })
    }

    promptForOptionRemove = () => {
        let response = window.confirm('Are you sure you want to remove this option?')

        if (response) {
            this.props.removeHandler()
        }
    }

    render() {
        const option = this.state.option
        console.log('option')
        console.log(option)
        return (
            <div className="item-option">
                <div className="remove-option-button" onClick={ () => { this.promptForOptionRemove() } }>X</div>
                <div className="option-row">
                    <div className="label">Name</div>
                    <input name="name" type="text" value={ option.name } className="text-input" onChange={ this.updateOptionName } />
                </div>
                <div className="option-row">
                    <div className="label">Required?</div>
                    <input type="checkbox" name="isRequired" checked= { (option.isRequired ? true : false) } className="text-input" onChange={ this.updateOptionRequired } />
                </div>
                <div className="option-row">
                    <div className="label">Type</div>
                    <select name="type" className="text-input" onChange={ this.updateOptionType } value={ option.type }>
                        <option value="bool">Yes/No</option>
                        <option value="select"> Choose One</option>
                        <option value="multi-select">Choose Many</option>
                    </select>
                </div>
                { ( option.type === 'select' || option.type === 'multi-select' ?
                    <div className="option-row">
                        <div className="option-list">
                            { ( option.optionList ? option.optionList.map( (opt) => (
                                <div key={opt.optionListItemId} className="option-list-item">
                                    <input value={opt.name} onChange={ (event) => this.updateOptionItemValue(opt, event.target.value) } /> <span className="item-control" onClick={ () => this.removeOptionItem(opt) }>-</span>
                                </div>
                                )
                            ) : null )}
                            <div className="option-list-item" style={{textAlign: 'center'}}>
                                <span className="item-control" onClick={ this.addOptionItem }>+</span>
                            </div>
                        </div>
                    </div>
                : null ) }
                {/* <input name="description" value={ option.description } className="text-input" onChange={ (event) => this.updateOptionDescription(event)} /> */}
            </div>
        )
    }

}

export default ItemOption