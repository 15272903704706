import React from 'react'
import { Link } from 'react-router-dom'

const dashboardHome = (props) => {
    console.log('this.props.match.path in home')
    console.log(props.match.path)
    return (
        <div>
            <h1>Home</h1>
            <Link to={ props.match.path + "/items" }>Items</Link>
        </div>
    )
}

export default dashboardHome