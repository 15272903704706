import React from 'react'
import OptionCheckbox from './OptionCheckbox'

const optionSelect = (props) => {

    let optionItems = props.options.map( option => {

        console.log('checking if select option is set for ' + option.optionListItemId)
        console.log(props.selectionDetails)
        console.log((props.selectionDetails ? props.selectionDetails.options : null))
        let isSelected = ( props.selectionDetails && props.selectionDetails.options ? props.selectionDetails.options.filter( opt => opt.optionListItemId === option.optionListItemId).length === 1 : false )
        console.log('isselected')
        console.log(isSelected)
        return (
            <div key={option.optionListItemId}>{option.name} <OptionCheckbox option={option} parentOption={props.parentOption} saveOption={props.saveOption} isSelected={isSelected} /> </div>
        )
    })

    return (
        <div>
            {optionItems}
        </div>
    )
}

export default optionSelect