import React, { Component } from 'react'
import axios from 'axios'
import { rest } from '../../tools'
import './KitchenScreen.scss'
import OrderItemSummary from '../CartScreen/OrderItemSummary/OrderItemSummary'
import classNames from 'classnames'
import ORDER_STATE from '../../enums/OrderState'
import pusher from '../../pusher'

class KitchenScreen extends Component {

    state = {
        orders: []
    }

    async componentDidMount() {
        this.setOrderStatus = this.setOrderStatus.bind(this)

        await this.requestOrders()

        var channel = pusher.subscribe('orders');
        channel.bind('status-changed', async (data) => {
            console.log('kitchen event')
            console.log(JSON.stringify(data));

            let orders = this.state.orders

            if (data.status === ORDER_STATE.SUBMITTED) {
                let requestUri = rest.getUri(rest.endpoint.order + data.orderId)
                let res = await axios.get(requestUri)
                let newOrder = res.data
                newOrder.userName = await this.getUserName(newOrder.userId)

                let newOrders = orders.filter( order => order.orderId !== newOrder.orderId)
                newOrders.push(newOrder)

                orders = newOrders

            } else {
                let order = orders.filter( order => order.orderId === data.orderId)[0]
                order.status = data.status
            }

            this.setState( {
                orders: orders
            })
        });
    }

    async requestOrders() {
        let requestUri = rest.getUri(rest.endpoint.order)
        let res = await axios.get(requestUri)

        console.log(res.data)

        let orders = res.data.orders

        console.log('about to process orders')
        // async for each
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i]
            console.log('processing order ' + order.orderId)

            if (order.userId != null) {
                order.userName = await this.getUserName(order.userId)
            } else {
                order.userName = 'not set'
            }

            console.log(order.userName)

            console.log('done processing order ' + order.orderId)
        }

        console.log('setting state')
        this.setState({
            orders: orders
        })
    }

    async getUserName(userId) {
        let requestUri = rest.getUri(rest.endpoint.user + userId)
        let res = await axios.get(requestUri)

        return res.data.name
    }

    async setOrderStatus(orderToUpdate, status) {
        let requestUri = rest.getUri(rest.endpoint.order + orderToUpdate.orderId)
        let res = await axios.post(requestUri, { status: status })

        let orders = this.state.orders

        orders.forEach( order => {
            if (order.orderId === orderToUpdate.orderId) {
                order.status = res.data.status // set new status
            }
        })

        this.setState({
            orders: orders
        })
    }

    renderOrder(order) {
        let getClasses = (order) => classNames({
            "order": true,
            "in-progress": order.status === ORDER_STATE.IN_PROGRESS,
            "submitted": order.status === ORDER_STATE.SUBMITTED,
            "completed": order.status === ORDER_STATE.COMPLETED,
        })

        return (
            <div className={getClasses(order)} key={order.orderId}>
                <h1>For {order.userName}</h1>
                <div>
                    { (order.status !== ORDER_STATE.IN_PROGRESS ? <button onClick={ () => this.setOrderStatus(order, ORDER_STATE.IN_PROGRESS)}>In Progress</button> : null)}
                    { (order.status !== ORDER_STATE.COMPLETED ? <button onClick={ () => this.setOrderStatus(order, ORDER_STATE.COMPLETED)}>Completed</button> : null)}
                    { (order.status !== ORDER_STATE.SUBMITTED ? <button onClick={ () => this.setOrderStatus(order, ORDER_STATE.SUBMITTED)}>Return To Submitted</button> : null)}
                </div>
                <span>{order.orderId} on {new Date(order.submitTime).toLocaleDateString()} @ {new Date(order.submitTime).toLocaleTimeString()}</span>
                <OrderItemSummary order={order} showRemoveButton={false}/>
            </div>
        )
    }
    renderOrders(status) {
        let orders = {}

        this.state.orders.forEach(order => {

            if (orders[order.status] == null) {
                orders[order.status] = []
            }

            orders[order.status].push(this.renderOrder(order))
        })

        return orders
    }

    sortBySubmitTime(order1, order2) {
        return order1.submitTime - order2.submitTime
    }

    render() {

        let orders = this.renderOrders()

        let inProgressOrders = (orders[ORDER_STATE.IN_PROGRESS] || []).sort(this.sortBySubmitTime)
        let submittedOrders = (orders[ORDER_STATE.SUBMITTED] || []).sort(this.sortBySubmitTime)
        let completedOrders = (orders[ORDER_STATE.COMPLETED] || []).sort(this.sortBySubmitTime)

        return (
            <div className="kitchen-screen">
                <div className='title'>Kitchen</div>
                <div>Orders</div>
                <div className='title in-progress'>In Progress</div>
                <div className='order-list'>{inProgressOrders}</div>
                <div className='title submitted'>Submitted</div>
                <div className='order-list'>{submittedOrders}</div>
                <div className='title completed'>Completed</div>
                <div className='order-list'>{completedOrders}</div>
            </div>
        )

    }
}

export default KitchenScreen