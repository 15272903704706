import React, { createContext } from 'react'
import { rest } from '../tools.js'
import axios from 'axios'
import ORDER_STATE from '../enums/OrderState'
import pusher from '../pusher'

export const OrderContext = createContext({
    isVerified: false,
    order: {
        items: []
    },
    addToOrder: () => {},
    removeFromOrder: () => {},
    cancelOrder: () => {},
    itemCount: () => {},
    submitOrder: () => {},
    createNewOrder: () => {}
})

export class OrderProvider extends React.Component {

    // NOTE state variable is just above render because setting functions doesn't work until its defined

    async componentDidMount() {

        console.log('OrderProvider cdm')
        let orderId = localStorage['orderId']

        console.log(orderId + ' from localstorage')

        if (orderId) {

            let res = await axios.get(rest.getUri('order/' + orderId))
            console.log('response from loading order')
            console.log(res)
            const order = res.data

            this.setState({
                isVerified: true,
                order: order
            })
        } else {
            this.setState({ isVerified: true })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.order.orderId == null && this.state.order.orderId != null) {
            var channel = pusher.subscribe('order-' + this.state.order.orderId);

            channel.bind('status-changed', (data) => {
                console.log('user order status change')
                console.log(JSON.stringify(data));

                let order = this.state.order

                order.status = data.status

                this.setState({
                    order: order
                })
            });
        }

    }

    addToOrder = async ( item, selectedOptions ) => {
        console.log("addToOrder called: " + item.name)

        let userId = localStorage['userId']

        let order, requestUri, payload = null
        const orderId = this.state.order.orderId

        item.selectedOptions = selectedOptions
        console.log("selectedOptions")
        console.log(selectedOptions)

        if (orderId) {
            requestUri = rest.getUri(rest.endpoint.orderItem(orderId))
            payload = { item: item }
        } else {
            requestUri = rest.getUri(rest.endpoint.order)
            payload = { items: [ item ], userId: userId }
        }

        let res = await axios.post(requestUri, payload)
        // todo: if this fails, do we queue up items in local state so we can try again later?
        // --- if so, this should take an array of items, or an object order, and we just keep updating it?
        // --- or do we just add 1 item at a time and have an items/ endpoint?
        order = res.data
        console.log('order')
        console.log(order)
        this.setState({ order: order })
        localStorage['orderId'] = order.orderId

        console.log('set localstorage orderId to ' + order.orderId)
        console.log(localStorage)
    }

    removeFromOrder = async ( item ) => {
        console.log('removing item #' + item.orderItemId)

        let order, requestUri = null
        const orderId = this.state.order.orderId

        requestUri = rest.getUri(rest.endpoint.orderItem(orderId, item.orderItemId))

        let res = await axios.delete(requestUri)

        order = res.data
        console.log('order')
        console.log(order)
        this.setState({ order: order })
    }

    cancelOrder = async ( item ) => {
        // set status to submitted (items should already be on server)
        console.log('submitting order')
        let requestUri = rest.getUri(rest.endpoint.order + this.state.order.orderId)
        let payload = { status: ORDER_STATE.CANCELLED } // todo: make this an enum?
        console.log(requestUri)
        console.log(payload)
        let res = await axios.post(requestUri, payload)
console.log('after post')
        // check if this failed and don't update the order
        let order = res.data
        console.log('order')
        console.log(order)
        this.setState({ order: order })
    }

    submitOrder = async () => {
        // set status to submitted (items should already be on server)
        console.log('submitting order')
        let requestUri = rest.getUri(rest.endpoint.order + this.state.order.orderId)
        let payload = { status: ORDER_STATE.SUBMITTED } // todo: make this an enum?
        console.log(requestUri)
        console.log(payload)
        let res = await axios.post(requestUri, payload)
console.log('after post')
        // check if this failed and don't update the order
        let order = res.data
        console.log('order')
        console.log(order)
        this.setState({ order: order })
    }

    createNewOrder = () => {
        delete localStorage['orderId']
        this.setState({ order: this.emptyOrder() })
    }

    emptyOrder = () => {
        return {
            items: []
        }
    }

    itemCount = ( item ) => {
        return this.state.order.items.filter( i => i.itemId === item.itemId).length
    }

    requestOrderStatus = async () => {
        let requestUri = rest.getUri(rest.endpoint.order + this.state.order.orderId)
        let res = await axios.get(requestUri)

        this.setState({
            order: res.data
        })
    }

    state = {
        isVerified: false,
        order: {
            items: []
        },
        addToOrder: this.addToOrder,
        removeFromOrder: this.removeFromOrder,
        cancelOrder: this.cancelOrder,
        itemCount: this.itemCount,
        submitOrder: this.submitOrder,
        createNewOrder: this.createNewOrder
    }

    render() {
        return (
            <OrderContext.Provider value={this.state}>
                {this.props.children}
            </OrderContext.Provider>
        )
    }
}

export const OrderConsumer = OrderContext.Consumer