import React from 'react'
import { OrderConsumer } from '../../../context/OrderContext'

const orderItemSummary = (props) => (
    <div>
        {props.order.items.map((item, index) => (
            <div key={index} className={"cart-item"}>
                <OrderConsumer>
                    { ({ removeFromOrder }) => {
                            return ( props.showRemoveButton ? <button className="red remove-button" onClick={() => removeFromOrder(item)}>X{props.showRemoveButton}</button> : null)
                        }

                    }
                </OrderConsumer>
                <div className={"details"}>
                    <div className={"name"}>{item.name}</div>
                    { console.log("item.selectedOptions")}
                    { console.log(item.selectedOptions)}
                    {( item.selectedOptions ?
                        <ul className={"options"}>{Object.values(item.selectedOptions).map( (option, index) => { console.log(option); return (<li key={index}>{(option ? option.name + (option.options ? ': ' + option.options[0].name : '') : 'empty')}</li>)})}</ul>
                        : <div className={"options"}>no options</div>
                    )}
                </div>
            </div>
        ))}
    </div>
)

export default orderItemSummary