import React, { Component } from 'react'
import OrderItemOption from './OrderItemOption/OrderItemOption'
import './OrderItem.scss'
import { OrderConsumer } from '../../../context/OrderContext'

class OrderItem extends Component {

    state = {
        showOptions: false,
        selectedOptions: {}
    }

    showDetails() {
        // open options
        console.log("showDetails clicked")
        if (this.props.item.options != null) {
            console.log(this.props.item.options)
            console.log("showing details")
            this.setState({showOptions: !this.state.showOptions})
        }
    }

    clearOptions() {
        this.setState({
            selectedOptions : {}
        })
    }

    setOption(option) {
        console.log('setOption called')
        console.log(option)
        console.log(option.optionId)

        let selectedOptions = this.state.selectedOptions

        console.log('selectedOptions before add')
        console.log(selectedOptions)

        selectedOptions[option.optionId] = option

        this.setState({
            selectedOptions: selectedOptions // TODO: do this the smart way
        })
        console.log('selectedOptions after state update')
        console.log(selectedOptions)
    }

    removeOption(option) {
        console.log('removeOption called')
        console.log(option)
        console.log(option.optionId)

        let selectedOptions = this.state.selectedOptions
        console.log(selectedOptions)
        delete selectedOptions[option.optionId]

        // TODO: do this the smart way
        this.setState({
            selectedOptions: selectedOptions
        })

        console.log(selectedOptions)
    }

    render() {
        let item = this.props.item
        let options = ( item.options != null
            ? item.options.map( option => <OrderItemOption option={option} selectedOptions={this.state.selectedOptions} key={option.optionId} setOption={this.setOption.bind(this)} removeOption={this.removeOption.bind(this)} /> )
            : [])

        // check if all required items are filled out
        let unsetRequiredExist = false
        if (item.options) {
            unsetRequiredExist = item.options.some( opt => {
                // check if its set based on type
                if (opt.isRequired) {
                    let notSelected = Object.values(this.state.selectedOptions).filter( so => so.optionId === opt.optionId)[0] == null

                    if (notSelected) {
                        return true
                    }
                }
                return false
            })
console.log('unsetRequiredExist')
console.log(unsetRequiredExist)

        }


        return (
            <OrderConsumer>
                { ( { addToOrder } ) =>
                    <div className="item-wrapper">
                        <div className="item-count">{( this.props.countForItem > 0 ? <span>{ this.props.countForItem }x </span> : null)}</div>
                        <div className="item">
                            <div className="name">
                                { item.name }
                            </div>
                            <div className="details">
                                <div className="description">{ item.description }</div>
                                <div className={(this.state.showOptions ? 'show-options' : 'options')}>
                                    { ( options.length > 0 ? options : null )}
                                    <div className="option-footer">
                                        <button onClick={ () => { addToOrder(item, this.state.selectedOptions); this.clearOptions(); } } disabled={unsetRequiredExist} className="button">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-button">
                            { options.length === 0
                                ? <button onClick={ () => addToOrder(item) } className="button">Add</button>
                                : <button onClick={this.showDetails.bind(this)} className="button">Options</button> }
                        </div>

                    </div>
                }
            </OrderConsumer>
        )
    }
}

export default OrderItem;