import React from 'react'
import { Redirect } from 'react-router-dom'
import { UserConsumer } from '../../context/UserContext'
import './WelcomeScreen.scss'

class WelcomeScreen extends React.Component {

    state = {
        name: ''
    }

    updateName(value) {
        this.setState({ name: value })
    }

    render() {
        return (
            <UserConsumer>
                {({ updateName, isUserLoaded, isVerified }) => (
                     (isVerified ?
                        <div className="welcome-screen">
                            { isUserLoaded ? <Redirect to="/order"/> : null }
                            <h1>
                                Welcome to {this.props.title}
                            </h1>
                            <div className="greeting">
                                Enter your name to get started:
                            </div>
                            <input className="name-input" name="name" onChange={event => {this.updateName(event.target.value)}} />

                            <button className="green" onClick={ async (_) => {
                                    await updateName(this.state.name)
                                }}>Continue</button>
                        </div>
                    : null)
                )}
            </UserConsumer>
        )
    }
}

export default WelcomeScreen