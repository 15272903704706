export const rest = {
    server: process.env.REACT_APP_API_URL,
    getUri(endpoint) {
        return this.server + endpoint
    },
    endpoint: {
        user: 'user/',
        order: 'order/',
        orderItem: (orderId, itemIndex) => 'order/' + orderId + '/item/' + (itemIndex ? itemIndex : ''), // todo: find out how to reference endpoint.order
        item: 'item/'
    }
}