import React, { createContext } from 'react'
import { rest } from '../tools.js'
import axios from 'axios'

export const ItemContext = createContext({
    items: [],
    getItems: () => {},
    saveItem: () => {},
    deleteItem: () => {},
    emptyItem: () => {},
})

export class ItemProvider extends React.Component {

    // NOTE state variable is just above render because setting functions doesn't work until its defined

    async componentDidMount() {

        this.getItems()

        // console.log('ItemProvider cdm')
        // let orderId = localStorage['orderId']

        // console.log(orderId + ' from localstorage')

        // if (orderId) {

        //     let res = await axios.get(rest.getUri('order/' + orderId))
        //     console.log('response from loading order')
        //     console.log(res)
        //     const order = res.data

        //     this.setState({
        //         isVerified: true,
        //         order: order
        //     })
        // } else {
        //     this.setState({ isVerified: true })
        // }
    }

    getItems = async () => {
        let res = await axios.get(rest.getUri(rest.endpoint.item))
        console.log('items')
        console.log(res.data.items)

        this.setState( {
            items: res.data.items
        })
    }

    saveItem = async ( item ) => {
        console.log('saving item')
        console.log(item)

        let itemId = item.itemId

        if (itemId) {

            let res = await axios.post(rest.getUri('item/' + itemId), item)
            console.log('response from updating item')
            console.log(res)
            const order = res.data

            this.setState({
                isVerified: true,
                order: order
            })
        } else {
            let res = await axios.post(rest.getUri('item/'), item)
            console.log('response from creating new item')
            console.log(res)
        }

        this.getItems() // do this the expensive way for now
    }

    deleteItem = async ( item ) => {
        console.log('removing item #' + item.itemId)

        let order, requestUri= null

        requestUri = rest.getUri(rest.endpoint.item + item.itemId)

        let res = await axios.delete(requestUri)

        this.getItems() // do this the expensive way for now
    }



    emptyItem = () => {
        return {

        }
    }

    state = {
        items: [],
        getItems: this.getItems,
        saveItem: this.saveItem,
        deleteItem: this.deleteItem,
        emptyItem: this.emptyItem,
    }

    render() {
        return (
            <ItemContext.Provider value={this.state}>
                {this.props.children}
            </ItemContext.Provider>
        )
    }
}

export const ItemConsumer = ItemContext.Consumer