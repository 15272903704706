import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { UserConsumer } from '../../context/UserContext'
import axios from 'axios'
import { rest } from '../../tools'
import OrderItem from './OrderItem/OrderItem'
import { OrderConsumer } from '../../context/OrderContext'
import Aux from '../../hoc/Aux'
import ORDER_STATE from '../../enums/OrderState'

import './OrderScreen.scss'

class OrderScreen extends Component {

    state = {
        itemsAvailable: [],
        itemsSelected: []
    }

    async componentDidMount() {
        let res = await axios.get(rest.getUri('item'))
        console.log(res)
        const items = res.data
        console.log(items)

        this.setState({ itemsAvailable: items.items })
    }

    render() {

        return (
            <Aux>
                <UserConsumer>
                    {({ user, isVerified, isUserLoaded }) =>
                        <Aux>
                            { isVerified && !isUserLoaded ? <Redirect to="/"/> : null }
                            <h1>Hi {user.name}!</h1>
                        </Aux>
                    }
                </UserConsumer>
                <div>
                    What can we get you today?
                </div>
                <OrderConsumer>
                    {({ isVerified, order, itemCount }) =>
                        <div className="order-screen">
                            {(isVerified
                                && (order.status === ORDER_STATE.SUBMITTED
                                || order.status === ORDER_STATE.IN_PROGRESS
                                || order.status === ORDER_STATE.COMPLETED)
                                ? <Redirect to="/cart"/> : null)}
                            <div className="show-cart-section">
                                {(order.items.length > 0 ? <span>({order.items.length}) <Link className="show-cart-link" to="/cart">Show Cart</Link></span> : null )}
                            </div>

                            <div className="items">
                                { this.state.itemsAvailable.map( (item, index) => <OrderItem item={item} key={index} countForItem={itemCount(item)} /> ) }
                            </div>
                        </div>
                    }
                </OrderConsumer>
            </Aux>
        )
    }
}

export default OrderScreen