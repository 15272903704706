import React, { Component } from 'react'
import ItemOption from '../ItemOption/ItemOption'
import { ItemConsumer } from '../../../../context/ItemContext'
import Aux from '../../../../hoc/Aux'
import './ItemDetail.scss'

class ItemDetail extends Component {
    state = {
        name: '',
        description: '',
        options: []
    }

    componentDidMount() {
        const item = ( this.props.item ? this.props.item : {} )

        this.setState({
            ...item
        })
    }

    updateOption = (option) => {

    }

    removeOption = (option) => {

    }

    updateName = (event) => {
        let value = event.target.value
        this.setState({
            name: value
        })
    }

    updateDescription = (event) => {
        let value = event.target.value
        this.setState({
            description: value
        })
    }

    addNewOption = () => {

        let options = this.state.options

        options.push({
            optionId: (new Date()).getTime()
        })

        this.setState({
            options: options
        })
    }

    removeOption = (optionToRemove) => {
        console.log("todo: remove option - " + optionToRemove.optionId)


        let options = this.state.options

        console.log(optionToRemove)
        options = options.filter(function( option ) {
            return option.optionId !== optionToRemove.optionId;
        })

        console.log(options)

        this.setState({
            options: options
        })
    }

    clearOnSave = () => {
        if (this.props.clearOnSave) {
            this.setState({
                name: '',
                description: '',
                options: []
            })
        }
    }

    promptForItemRemove = ( callback ) => {
        let response = window.confirm('Are you sure you want to remove this item?')

        if (response && callback) {
            callback()
        }
    }

    render() {
        const item = this.state
        return (
            <div className="item-detail">
                <div className="detail-row">
                    <div className="label">Name</div>
                    <input name="name" value={ item.name } className="text-input" onChange={ this.updateName } />
                </div>
                <div className="detail-row">
                    <div className="label">Description</div>
                    <input name="description" value={ item.description } className="text-input" onChange={ this.updateDescription } />
                </div>
                <div className="detail-row">
                    <div className="label">Options</div>
                    <div>
                        { ( item.options ? item.options.map( option => <div key={option.optionId}><ItemOption option={option} removeHandler={ () => this.removeOption(option) } /></div>) : null ) }
                        <button onClick={ this.addNewOption }>add new option</button>
                    </div>

                </div>
                <div className="detail-row">
                    <ItemConsumer>
                        { ( { saveItem, deleteItem }) =>
                            <Aux>
                                <button className="green" onClick={ () => { saveItem(item); this.clearOnSave() } }>Save</button>
                                {( item.itemId ? <button className="red" onClick={ () => this.promptForItemRemove( () => deleteItem(item) ) }>Delete</button> : null)}
                            </Aux>
                        }
                    </ItemConsumer>
                </div>
            </div>
        )
    }
}

export default ItemDetail